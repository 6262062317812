import React from 'react';
import {setEnvVariables} from "../../config/EnvUtil";

const doNotSellLink = "https://privacyportal.onetrust.com/webform/599133ba-bafa-4e24-8173-6e59b6c96dab/18d7bc00-2ee4-40b9-b140-ad28dbaad069";
let endpoint = setEnvVariables(window.location.hostname);
const privacyPolicy = endpoint.privacyPolicyLink;

const Footer = () => {

    

    return (
        <footer className="main-footer footer ">
            <strong>© 2024 McKesson Corporation. </strong> All rights reserved.
            &nbsp;  &nbsp;  &nbsp; &nbsp;<span className="footerText"> <a
            href="http://www.mckesson.com/about-mckesson/who-we-are/" target="_blank"
            rel="noopener noreferrer">About Us</a> | <a href="https://www.mckesson.com/Contact-Us/" target="_blank"
                                                        rel="noopener noreferrer">Contact Us</a> | <a
            href={privacyPolicy} target="_blank" rel="noopener noreferrer">Privacy Notice</a> | <a
            href={doNotSellLink} target="_blank" rel="noopener noreferrer">Do Not Sell My Personal Information</a> | <a
            href="https://www.mckesson.com/About-McKesson/Disclaimer/" target="_blank"
            rel="noopener noreferrer">Disclaimer</a> | <a href="/web/Faqs" target="_blank"
                                                          rel="noopener noreferrer">FAQs</a></span>

        </footer>

    );

}

export default Footer